<template>
  <div class="row card-container">
    <div class="col">
      <form class="form card card-body responsive">
        <h1>管理者ログイン</h1>
        <div class="form-group mx-auto">
          <label for="inputEmail">メールアドレス</label>

          <input id="inputEmail" v-model="email" class="form-control mt-2" placeholder="" required type="email">
          <div v-show="errors['email']" id="err_inputEmail" class="form-error">{{ errors['email'] }}</div>

        </div>
        <div class="form-group mx-auto mt-2">
          <label for="inputPassword">パスワード</label>
          <input id="inputPassword" v-model="password" class="form-control mt-2" placeholder="" required
                 type="password">
          <div v-show="errors['password']" id="err_inputPassword" class="form-error">{{ errors['password'] }}</div>
        </div>
        <div v-show="isFirstLogin" class="form-group mx-auto mt-2">
          <label for="dob">生年月日</label>
        </div>
        <div v-show="isFirstLogin" class="row dob-section">
          <div class="d-inline-flex px-0">
            <div class="input-group custom-select-year parent-block">
              <select id="inputGroupSelectYear" v-model="year" class="form-control select_year form-select">
                <option v-for="year in years"
                        :value="year">{{ year }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block">
              <select id="inputGroupSelectMonth" v-model="month" class="form-control select_month form-select">
                <option v-for="month in months"
                        :value="month">{{ month }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block m-r-0">
              <select id="inputGroupSelectDate" v-model="date" class="form-control select_date form-select">
                <option v-for="day in days"
                        :value="day">{{ day }}
                </option>
              </select>
            </div>
          </div>
          <div v-show="errors['birthday']" id="err_inputBirth" class="form-error">{{ errors['birthday'] }}</div>
        </div>

        <!--                <div class="form-check mx-auto">-->
        <!--                  <input class="form-check-input" type="checkbox" :checked="isRemember" id="flexCheckDefault">-->
        <!--                  <label class="form-check-label" for="flexCheckDefault">-->
        <!--                      次回から自動的にログイン-->
        <!--                  </label>-->
        <!--                </div>-->

        <div class="btn btn-primary font-weight-bold mx-auto m-t-30" v-on:click="doLogin">ログイン</div>
        <router-link :to="{ name: 'ResetPassword' }" class="text-center m-t-20 forgot-password ashen-link">パスワードを忘れた場合
        </router-link>
      </form>
    </div>
  </div>

</template>

<script>
import {mapActions} from 'vuex'
import Common from '../assets/jsadminclient/common'
import {AdminCredentials} from '../assets/jsadminclient/index'
import Helper from '../assets/jsadminclient/helper'

export default {
  name: "LoginWithDob",
  data() {
    return {
      response: {},
      loading: true,
      loginError: false,
      email: '',
      password: '',
      years: Helper.getRange(1920, (new Date).getFullYear(), 4),
      months: Helper.getRange(1, 12),
      days: Helper.getRange(1, 31),
      year: '1990',
      month: '01',
      date: '01',
      isRemember: false,
      errors: [],
      isFirstLogin: true
    }
  },
  methods: {
    ...mapActions(['Login']),
    async doLogin() {
      this.errors = [];

      try {
        const api = Common.createLoginApi()
        var params = new AdminCredentials()
        params.email = this.email
        params.password = this.password
        if (this.isFirstLogin) {
          params.birthday = this.year + "/" + this.month + "/" + this.date
        } else {
          params.birthday = null
        }
        params.remember = this.isRemember

        const jsonObject = await this.Login(params)
        // save cookie
        Common.saveFirstLogin()
        // is first login
        const isNeedSetup = jsonObject.user.requireUserInfo

        if (isNeedSetup) {
          this.$router.push({name: 'InitialLoginFirst'})
        } else {
          this.$router.push({name: 'Home'})
        }

      } catch (error) {
        const isLogin = Common.checkFirstLogin(error.response)
        if (isLogin) {
          Common.showToast("初回ログインもしくはパスワードリセット後には生年月日の入力が必要です。", true)
          this.isFirstLogin = true
          this.year = "1990"
          this.month = "01"
          this.date = "01"
        }
        Common.getErrorList(error.response, this.errors)
        Common.handleAPIError(error)
      }
    },
  },
  mounted() {
    this.isFirstLogin = Common.isFirstLogin()
  }
}
</script>

<style lang="scss" scoped>

.custom-select-year {
  width: 103px;
  margin-right: 8px;

  &:first-child {
    width: 127px;
  }

  &:last-child {
    margin-right: 0px !important;
  }
}

label {
  font-size: smaller;
}

.forgot-password {
  font-size: smaller;
}

/*Iphone 5s Media Query*/

@media only screen and (max-width: 320px) {
  .card {
    border: none !important;
  }
  .text-center {
    text-align: left !important;
    padding-left: 4px;
    font-weight: bold;
  }
  .mb-4 {
    margin-bottom: 1rem !important;
    margin-top: 20px;
  }
  .forgot-password {
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
    text-decoration: underline !important;
    color: #333333 !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 576px) {
  .hide-medium {
    display: none;
  }
  .card {
    .text-center {
      margin-top: 2rem;
      color: #333333;
    }

    .title {
      font-size: 30px;
    }

    .form-group {
      .form-control {
        height: 48px;
      }
    }

    .btn-light {
      color: #888888;
    }

    .reset-title {
      font-size: 20px !important;
      margin-left: 6rem !important;
    }

    .inputEmail {
      margin-bottom: 12px !important;
    }

    h5 {
      text-align: left !important;
      margin-left: 1em;
      font-weight: bold;
    }

    .cancel {
      color: #888888;
    }

    .dob-section {
      .parent-block {
        .child-block {
          position: absolute;
          background: #EFF2F7;
          justify-content: center;
          align-items: center;
          display: flex;
          border: 1px solid #CDDFF8;
          border-radius: 0px 4px 4px 0px;
          height: 100%;
          width: 2rem;
          z-index: 9999;
          right: 0;
          color: #888888;
        }
      }
    }

    .custom-select-year {
      width: 82px;

      &:first-child {
        width: 98px;
      }
    }
  }
}
</style>
